(function (angular) {
    angular
        .module('one.admin')
        .controller('FormDistributionController', FormDistributionController);

    FormDistributionController.$inject = ['$scope', 'FormService', 'FormEntryService', 'form', 'visitablePages', 'dataframe'];

    function FormDistributionController($scope, FormService, FormEntryService, form, visitablePages, dataframe) {
        $scope.form = form;
        $scope.visitablePages = visitablePages;

        $scope.openPublishFormModal = FormService.openPublishFormModal;

        var colors = [
            'rgba(113,192,37,.7)', // green
            'rgba(245,77,71,.7)', // red
            'rgba(151,187,205,.7)', // blue
            'rgba(253,180,92,.7)', // yellow
            'rgba(68,68,68,.7)', // grey
            'rgba(220,220,220,.7)', // light grey
            'rgba(70,191,189,.7)', // green
            'rgba(148,159,177,.7)', // grey
            'rgba(77,83,96,.7)' // dark grey
        ];

        $scope.colors = colors;

        // Make sure there are plenty of colors, so Chart.js does not generate random colors.
        for (var i = 0; i < 20; i++) {
            $scope.colors = $scope.colors.concat(colors);
        }

        $scope.loadingTrackingData = true;
        $scope.trackingData = [];

        FormEntryService.trackingData($scope.form.id).then(function (response) {
            $scope.trackingData = dataframe.init({
                data: response,
                cols: [
                    {
                        title: 'Initial URL',
                        name: 'initial_url',
                        display: function (row) {
                            return '<a ng-href="{{ row.initial_url }}" target="_blank" rel="noopener" ng-if="row.initial_url">{{ row.initial_url }}</a><span ng-if="!row.initial_url">{{ \'general.unknown\' | translate }}</span>';
                        },
                        weight: 2
                    },
                    {
                        title: 'Final URL',
                        name: 'final_url',
                        display: function (row) {
                            return '<a ng-href="{{ row.final_url }}" target="_blank" rel="noopener" ng-if="row.final_url">{{ row.final_url }}</a><span ng-if="!row.final_url">{{ \'general.unknown\' | translate }}</span>';
                        },
                        weight: 2
                    },
                    {
                        title: 'Count',
                        name: 'count',
                        display: function (row) {
                            return '{{ row.count | number }}';
                        },
                        weight: 1
                    }
                ],
                state: {
                    perPage: 5
                }
            });

            $scope.loadingTrackingData = false;
        });

        $scope.loadingDistribution = true;
        $scope.fields = [];

        FormEntryService.distribution($scope.form.id).then(function (fields) {
            var chartFields = [];

            angular.forEach(fields, function (field) {
                if (field.options.length > 1) {
                    field.chart = {
                        labels: [],
                        data: [],
                        options: {
                            responsive: true,
                            maintainAspectRatio: false
                        }
                    };

                    angular.forEach(field.options, function (option) {
                        field.chart.labels.push(option.value);
                        field.chart.data.push(option.count);
                    });

                    chartFields.push(field);
                }
            });

            $scope.fields = chartFields;
            $scope.loadingDistribution = false;
        });
    }
})(angular);
